import { useState } from "react";
import modalStyle from "../../styles/modal.module.scss";
import singleStyle from "../../styles/single.module.scss";
import CollectionButton from "../CollectionButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { closeIcon } from "../Icons";
import "swiper/css";
import "swiper/css/pagination";

export default function MobileMaterialModal({
  handleCloseModal,
  materialData,
  vendor,
  title,
  handleImageLoaded,
  imageLoaded,
  description,
  handleChangeImage,
  ROOT_SERVER,
  slug,
  loaded,
}) {
  const [imageIndex, setImageIndex] = useState(0);
  return (
    <div
      className={modalStyle.modalShade}
      style={{ alignItems: "flex-start" }}
      onClick={handleCloseModal}
    >
      <div
        style={{
          position: "absolute",
          top: "5px",
          right: "10px",
          color: "white",
          zIndex: 10,
          height: "30px",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#272b2b",
          padding: "5px 10px",
          borderRadius: "2px",
        }}
      >
        CLOSE {closeIcon}
      </div>
      {loaded ? (
        <div
          className={modalStyle.materialModal}
          onClick={(e) => e.stopPropagation()}
          style={{
            height: "auto",
            maxHeight: "90vh",
            maxWidth: "98vw",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#272b2bf8",
            borderRadius: "1px",
            justifyContent: "flex-start",
            marginTop: "40px",
          }}
        >
          <div className={modalStyle.modalContent}>
            {/* IMAGE CONTAINER START */}
            <div className={modalStyle.imageContainer}>
              <div
                style={{
                  position: "absolute",
                  left: "10px",
                  top: 5,
                  zIndex: 5,
                  fontFamily: "Montserrat-Bold",
                  fontSize: "12px",
                  textTransform: "uppercase",
                  color: "#FEFEFE",
                  filter: "drop-shadow(0px 0px 1px black)",
                }}
              >
                {vendor}
              </div>
              <div
                style={{
                  position: "absolute",
                  right: 40,
                  top: 5,
                  zIndex: 2,
                  color: "#FEFEFE",
                  filter: "drop-shadow(0px 0px 1px black)",
                }}
              >
                <CollectionButton materialId={materialData.id} />
              </div>
              {!imageLoaded && <div className="spinner" />}
              <Swiper
                pagination={true}
                navigation={true}
                loop={false}
                //ref={swiperRef}
                onSwiper={(swiper) => swiper.activeIndex === imageIndex}
                onSlideChange={(swiper) => {
                  handleChangeImage(swiper.activeIndex);
                  setImageIndex(swiper.activeIndex);
                }}
              >
                {materialData?.images?.length >= 1
                  ? materialData.images.map((item, i) => (
                      <SwiperSlide key={i}>
                        <img
                          alt={item}
                          className={singleStyle.materialImage}
                          src={`${ROOT_SERVER}${vendor}/${slug}/swatch/${item}`}
                          loading="lazy"
                          onLoad={handleImageLoaded}
                        />
                      </SwiperSlide>
                    ))
                  : false}
              </Swiper>
              <div className={modalStyle.imageSelector}>
                {materialData?.images?.map((item, i) => (
                  <div
                    key={i}
                    className={modalStyle.swatch}
                    style={{
                      backgroundColor:
                        imageIndex === i ? "#dddddd50" : "transparent",
                    }}
                  ></div>
                ))}
              </div>
            </div>
            {/* IMAGE CONTAINER END */}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "2px 10px",
              color: "#FEFEFE",
            }}
          >
            <h4 style={{ textTransform: "uppercase", color: "#00ffed" }}>
              {title}
            </h4>
            <h6>{description}</h6>
            <h3>${materialData.price}/ sq.ft.</h3>
            {materialData?.character.map((item) => (
              <h6 key={item}>{item}</h6>
            ))}
          </div>
        </div>
      ) : (
        <div>Loading it up</div>
      )}
    </div>
  );
}
