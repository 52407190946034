import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

function SpecialLogin({ path }) {
  const { changeUserInformation, userInformation } = useContext(UserContext);
  const { isLoggedIn } = userInformation;
  const [userEnter, setUserEnter] = useState(false);
  const [accessData, setAccessData] = useState([]);
  const guestPath = path.substring(1);
  //const params = useParams();
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();

  ///// URL points
  const material = queryParameters.get("material");
  const access = queryParameters.get("access");
  const collection = queryParameters.get("collection");

  const pathOptions = ["nike", "lib", "vans"];
  //const quickPath = params["*"];

  const getAccess = (access) => {
    const request = { accessId: access };
    axios
      .post("http://localhost:5001/access", request)
      .then((response) => response)
      .then((res) => {
        setAccessData(res.data[0]);
      });
  };

  const handleJump = (id, brand, userType) => {
    changeUserInformation({
      userId: id,
      userType: userType,
      vendor: brand,
      userName: brand + userType,
      isLoggedIn: true,
    });
    setUserEnter(true);
  };

  useEffect(() => {
    if (userEnter) {
      navigate(`/${material}`);
    }
    // eslint-disable-next-line
  }, [userEnter]);

  useEffect(() => {
    if (isLoggedIn) {
      if (material) {
        navigate(`/${material}`);
      }
      if (collection) {
        console.log("Special Login collection", collection);
      }
    } else {
      if (accessData && accessData.contentType === "matArr") {
        handleJump(accessData.accessId, accessData.brand, "guest");
        console.log("inside", accessData);
      }
    }
    // eslint-disable-next-line
  }, [accessData]);

  useEffect(() => {
    getAccess(access);
  }, [access]);

  // useEffect(() => {
  //   const isInThere = contentMatch.find((item) => item.id === content);
  //   if (isInThere && !isLoggedIn) {
  //     console.log("came in without niceness");
  //     handleJump(isInThere.id, isInThere.brand, isInThere.userType);
  //   } else {
  //     console.log("jumped without change");
  //     setUserEnter(true);
  //   }
  // }, [content, contentMatch, isLoggedIn, handleJump]);

  // ////// For entry with quickPath -- no other info
  // useEffect(() => {
  //   if (!isLoggedIn && pathOptions.includes(quickPath) && !content) {
  //     // handleSpecialUserLogin();
  //     console.log("Use effect at line 70");
  //     changeUserInformation({
  //       userId: 666,
  //       userType: "guest",
  //       vendor: quickPath,
  //       userName: quickPath + "guest",
  //       isLoggedIn: true,
  //     });
  //     navigate(`/library`);
  //   }
  //   // eslint-disable-next-line
  // }, [isLoggedIn, pathOptions, quickPath]);

  const handleSpecialUserLogin = () => {
    // if (!isLoggedIn) {
    //   changeUserInformation({
    //     userId: 666,
    //     userType: "guest",
    //     vendor: quickPath,
    //     userName: quickPath + "guest",
    //     isLoggedIn: true,
    //   });
    //   if (search) {
    //     navigate(`/${search}`);
    //   } else {
    //     navigate("/library");
    //   }
    // } else {
    //   navigate(`/${search}`);
    // }
  };

  if (pathOptions.includes(guestPath)) {
    return (
      <div className="content-full">
        <div className="empty-collection">
          <div className="empty-collection-message">
            <div className="text-box">
              <h2 className="login-view-heading">
                Special Login for {guestPath}
              </h2>
              <button className="button" onClick={handleSpecialUserLogin}>
                Click to enter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="content-full">
      <div className="empty-collection">
        <div className="empty-collection-message">
          <div className="text-box">
            <h2>Whoops! {guestPath} route doesn't exist</h2>
            {collection}
            <h4>go back to library</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecialLogin;
