import { useState, useEffect } from "react";
import MultiDragDrop from "./vendor/MultiDragDrop";
import axios from "axios";
import modalStyle from "../styles/modal.module.scss";
import { pdfIcon, closeIcon } from "./Icons";

function CollectionMailModal({
  showModal,
  setShowModal,
  checkedCollection,
  //collection,
}) {
  const [title, setTitle] = useState("my collection");
  const [who, setWho] = useState("");
  const [notes, setNotes] = useState("");
  const [compileResults, setCompileResults] = useState([]);
  const [articleNote, setArticleNote] = useState([]);
  const [returnData, setReturnData] = useState([]);
  const [pdf, setPdf] = useState("");
  const [pdfReady, setPdfReady] = useState(false);

  useEffect(() => {
    setCompileResults(
      checkedCollection.map((item, i) => ({
        id: item.id,
        slug: item.slug,
        vendor: item.vendor,
        title: item.title,
        description: item.description,
        image: item.images[0],
        note: articleNote[i],
      }))
    );
  }, [checkedCollection, articleNote]);

  useEffect(() => {
    //const arrayEmpty = new Array(checkedCollection.length);
    const arr = new Array(checkedCollection.length).fill().map(() => "");
    setArticleNote(arr);
  }, [checkedCollection]);

  // const handleArticleNote = (e, i) => {
  //   const getElements = [...articleNote];
  //   getElements[i] = e.target.value;
  //   setArticleNote(getElements);
  // };

  // const handleTestColl = () => {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       title: "hello there",
  //       //   who: who,
  //       //   notes: notes,
  //       //   title: title,
  //       //   email: "jsmith@asiatan.com",
  //     }),
  //   };

  //   fetch("http://localhost:5001/mail/coll", requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => window.open(data, "_blank", "noreferrer"));
  //   //console.log());
  // };
  const handleClear = () => {
    setReturnData([]);
    setPdfReady(false);
    setPdf("");
  };

  const openInNewTab = (link) => {
    window.open(link, "_blank");
  };

  const handleCloseModal = () => {
    setShowModal(false);
    body.style.overflow = "auto";
    body.style.paddingRight = "0px";
  };

  const handlePDFTest = () => {
    axios
      .post("http://localhost:5001/pdf", {
        material: returnData[0].materials,
        pageTitle: returnData[1].title,
        collectionDescription: returnData[1].description,
      })
      .then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            setPdfReady(true);
            setPdf(res.data);
            openInNewTab(res.data);
          }, 2000);
        }
      });
  };

  const handleOpenPDF = () => {
    window.open(pdf, "_blank");
  };

  const handleAddNote = () => {
    const newNote = {
      id: "note-" + Math.floor(Math.random() * 200),
      type: "note",
      slug: "note",
      vendor: "",
      title: "I'm a note baby",
      description: "",
      image: "",
      note: "this is the note spot",
    };

    const addNote = [...compileResults, newNote];
    setCompileResults(addNote);
  };
  const handleRemoveItem = (id) => {
    const remainingItems = compileResults.filter((items) => items.id !== id);
    setCompileResults(remainingItems);
  };

  const handleMailCollection = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        materials: compileResults,
        who: who,
        notes: notes,
        title: title,
        email: "jsmith@asiatan.com",
      }),
    };

    fetch("http://localhost:5001/mail/base", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setReturnData(data);
        // if (data.msg === "message sent") {
        //   console.log("something happened");
        //   setWho("");
        //   setNotes("");
        //   setArticleNote([]);
        //   setCompileResults([]);
        //   setShowModal(false);
        // } else {
        //   console.log(data);
        // }
      });
  };

  const body = document.querySelector("body");

  if (showModal) {
    body.style.overflow = "hidden";
    body.style.paddingRight = "10px";
    if (returnData.length >= 1) {
      const { materials } = returnData[0];
      const { title, description } = returnData[1];
      return (
        <div
          className="collection-modal"
          style={{
            backgroundColor: "#00000090",
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleCloseModal}
        >
          <div
            className="collection-mail-container"
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "fit-content",
              padding: "20px",
              backgroundColor: "#272b2bf8",
              color: "#fefefe",
              position: "relative",
            }}
          >
            <div
              style={{
                background: "black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "50px",
              }}
            >
              <h3>{title}</h3>
              <h5>{description}</h5>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {materials.map((item) => {
                if (item.slug === "note") {
                  return (
                    <div
                      key={item.slug}
                      style={{
                        width: "80vh",
                        height: "45px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.note}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={item.id}
                      style={{
                        margin: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#222",
                        borderRadius: "2px",
                        border: "1px solid black",
                        height: "250px",
                      }}
                    >
                      <div
                        style={{
                          width: "265px",
                          height: "200px",
                          overflow: "hidden",
                          borderRadius: "3px",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            fontSize: "10px",
                            textTransform: "uppercase",
                            top: "5px",
                            left: "5px",
                            filter: "drop-shadow(0 0 2px black)",
                          }}
                        >
                          {item.vendor}
                        </div>
                        <img
                          src={item.base}
                          alt={item.slug}
                          style={{ height: "200px" }}
                        />
                      </div>
                      <h4
                        style={{ textTransform: "uppercase", marginTop: "2px" }}
                      >
                        {item.title}
                      </h4>
                      <h6>{item.description}</h6>
                    </div>
                  );
                }
              })}
            </div>

            <div
              style={{
                height: "50px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <button className="button-highlight" onClick={handleClear}>
                Clear
              </button>
              {pdfReady ? (
                <button className="button-highlight" onClick={handleOpenPDF}>
                  PDF Ready to view
                </button>
              ) : (
                <button className="button-highlight" onClick={handlePDFTest}>
                  PDF
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        className="collection-modal-shade"
        style={{
          backgroundColor: "#00000080",
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          zIndex: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={handleCloseModal}
      >
        <div
          className="collection-mail-container"
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "fit-contents",
            padding: "20px",
            backgroundColor: "#272b2bf8",
            color: "#fefefe",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "40px",
              color: "#FEFEFE",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            SAVE COLLECTION TO PDF
            <div className={modalStyle.closeButton} onClick={handleCloseModal}>
              <span>close</span> {closeIcon}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className="collection-mail-input"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "45%",
                marginRight: "10px",
                maxWidth: "500px",
                minWidth: "450px",
              }}
            >
              <input
                type="text"
                name="title"
                autoComplete="off"
                placeholder="Collection Title"
                style={{
                  resize: "none",
                  height: "30px",
                  outline: "none",
                  border: "none",
                  borderBottom: "1px solid #FEFEFE",
                  backgroundColor: "transparent",
                  marginBottom: "5px",
                  color: "#fefefe",
                }}
                onChange={(e) => setTitle(e.target.value)}
              />
              <input
                type="text"
                name="email"
                autoComplete="off"
                placeholder="who goes there"
                style={{
                  resize: "none",
                  height: "30px",
                  outline: "none",
                  border: "none",
                  borderBottom: "1px solid #FEFEFE",
                  backgroundColor: "transparent",
                  marginBottom: "5px",
                  color: "#fefefe",
                }}
                onChange={(e) => setWho(e.target.value)}
              />
              <textarea
                type="text"
                name="notes"
                autoComplete="off"
                placeholder="Notes"
                style={{
                  resize: "none",
                  height: "70px",
                  backgroundColor: "transparent",
                  color: "#fefefe",
                  outline: "none",
                  border: "thin solid #FEFEFE20",
                  borderBottom: "2px solid #FEFEFE",
                  padding: "5px",
                }}
                onChange={(e) => setNotes(e.target.value)}
              />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "scroll",
                  gap: 2,
                }}
              >
                {Array.from(compileResults, (item) => coolImage(item))}
              </div>
            </div>

            <div
              className="collection-mail-image-box"
              style={{
                height: "100%",
                margin: "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#00000020",
                  width: "465px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  alignItems: "center",
                  padding: "0 10px",
                  userSelect: "none",
                  marginBottom: "7px",
                }}
              >
                <div>{compileResults.length} Materials Selected</div>
                <div>Reorder</div>
              </div>

              <MultiDragDrop
                compileResults={compileResults}
                setCompileResults={setCompileResults}
                handleRemoveItem={handleRemoveItem}
                notes={notes}
                setNotes={setNotes}
              />
            </div>
          </div>
          <div
            style={{
              paddingLeft: "50%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: "10px",
              }}
            >
              <div
                style={{
                  color: "#FEFEFE",
                  fontSize: "12px",
                  margin: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    compileResults.map((item) => item.id).join(",")
                  );
                }}
              >
                Copy results to clipboard:{" "}
                {compileResults.map((item) => item.id).join(",")}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button className="button-highlight" onClick={handleAddNote}>
                Add Note
              </button>
              <button
                className="button-highlight"
                onClick={handleMailCollection}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                Generate PDF {pdfIcon}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

function coolImage(data) {
  const { id, slug, vendor, title, image } = data;
  return (
    <div
      key={id}
      style={{
        width: "218px",
        height: "130px",
        color: "#FEFEFE",
        backgroundColor: "#2c2e2c",
        border: "1px solid #00000040",
        marginBottom: "2px",
      }}
    >
      <div style={{ width: "218px", height: "100px", overflow: "hidden" }}>
        <img
          style={{ width: "218px" }}
          src={`https://node.matlib.net/${vendor}/${slug}/thumbnail/${image}`}
          alt={slug}
        />
      </div>
      <div
        style={{
          width: "218px",
          height: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h6 style={{ textTransform: "uppercase" }}>{title}</h6>
      </div>
    </div>
  );
}
export default CollectionMailModal;
