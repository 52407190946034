import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Swatch from "../components/Swatch";

export default function ExternalEntry() {
  const [accessData, setAccessData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [queryParameters] = useSearchParams();
  const access = queryParameters.get("access");
  const [materials, setMaterials] = useState([]);

  const getAccess = (access) => {
    const request = { accessId: access };
    axios
      .post("http://localhost:5001/access", request)
      .then((response) => response)
      .then((res) => {
        setAccessData(res.data[0]);
      });
  };

  useEffect(() => {
    if (access) {
      getAccess(access);
    }
  }, [access]);

  useEffect(() => {
    if (accessData.contentType === "matArr") {
      const matArray = JSON.parse(accessData.materialArray);
      setMaterials(matArray);
      setLoaded(true);

      //   let result = allMaterials.filter(function (item) {
      //     return matArray.indexOf(item.id) != -1;
      //   });

      //   console.log(result);

      //setMaterials()
    }
  }, [accessData]);

  function getDate(MySQLDate) {
    MySQLDate = new Date().toISOString().slice(0, 19).split("T")[0];
    return MySQLDate;
  }

  return (
    <div className="content">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "70px",
          marginBottom: "10px",
          color: "#FEFEFE",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 20,
            right: 10,
            opacity: 0.5,
            textAlign: "right",
          }}
        >
          <p>{getDate(accessData.createDate)}</p>
          <p>{accessData.createdBy}</p>
        </div>
        <div style={{ fontSize: "20px", textTransform: "uppercase" }}>
          {accessData.title}
        </div>
        <div>{accessData.description}</div>
      </div>
      <div className="swatch-view">
        {loaded
          ? materials?.map((material) => (
              <Swatch key={material} id={material} />
            ))
          : "loading"}
      </div>
      <object
        data="http://localhost:5001/this-is-the-collection.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Alternative text - include a link{" "}
          <a href="http://localhost:5001/this-is-the-collection.pdf">
            to the PDF!
          </a>
        </p>
      </object>
    </div>
  );
}
