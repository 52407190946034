import { Outlet } from "react-router-dom";
import landingStyle from "../styles/landing.module.scss";

function Landing() {
  return (
    <div className="content-full">
      <div
        style={{
          backgroundImage: `url(./landing2.jpg)`,
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: "-1",
          opacity: 0.2,
          filter: "hue-rotate(355deg)",
        }}
      ></div>
      {/* <div className="background-design">{backgroundDesign}</div> */}
      <div className={landingStyle.loginView}>
        <div className={landingStyle.loginViewContent}>
          <h1>MATLIB</h1>
          <h4>Digital Materials Library</h4>
          <div style={{ width: "inherit" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
