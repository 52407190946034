import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import inputStyle from "../../styles/input.module.scss";
import { ROOT_SERVER } from "../../utilities/Static";
function DragDrop({ images, vendor, slug, setImages, setPreviewImage }) {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      images,
      result.source.index,
      result.destination.index
    );
    setImages(items);
  }
  const handleRemove = (item) => {
    const removedArray = images.filter((image) => image !== item);
    setImages(removedArray);
  };
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              className={inputStyle.inputSwatchContainer}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className={inputStyle.inputSwatchHeader}>
                <div className={inputStyle.inputSwatchHeaderTitle}>SWATCH</div>
              </div>
              {images?.map((image, index) => (
                <Draggable key={image} draggableId={image} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={inputStyle.dragDropItem}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <img
                        src={`${ROOT_SERVER}${vendor}/${slug}/thumbnail/${image}`}
                        alt={image}
                        onClick={() => setPreviewImage(image)}
                      />
                      <div
                        className={inputStyle.folderItemAdd}
                        onClick={() => handleRemove(image)}
                      >
                        {images.includes(image) ? "remove" : "add"}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default DragDrop;
