import { useNavigate } from "react-router-dom";

function Help() {
  const navigate = useNavigate();

  const menuSidebarStyle = {
    width: "20%",
    padding: "22px 40px",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    left: 0,
    top: 0,
  };
  const helpBodyStyle = {
    width: "80%",
    padding: "20px",
    marginLeft: "20%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    scrollPadding: "200px",
  };
  const helpSectionStyle = {
    backgroundColor: "#00000020",
    border: "thin solid #00000040",
    borderRadius: "12px",
    padding: "20px",
  };
  const sectionHeaderBump = {
    marginBottom: "20px",
  };
  const spacing = {
    margin: "0 10px 10px 10px ",
  };
  const handleClickScroll = (section) => {
    const element = document.getElementById(section);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className="content"
      style={{
        margin: "5.25rem 0rem 1.25rem 0rem",
        justifyContent: "flex-start",
        color: "#FEFEFE",
      }}
    >
      <div style={menuSidebarStyle}>
        <h1
          style={{
            marginBottom: "100px",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          MATLIB HELP
        </h1>

        <button
          className="button-highlight"
          onClick={() => handleClickScroll("section-1")}
        >
          Navigating the App
        </button>
        <button
          className="button-highlight"
          onClick={() => handleClickScroll("section-2")}
        >
          Storing collection
        </button>
        <button
          className="button-highlight"
          onClick={() => handleClickScroll("privacy")}
        >
          Privacy Policy
        </button>
        <button
          className="button-highlight"
          onClick={() => navigate("/library")}
        >
          Back to the library
        </button>
      </div>
      <div style={helpBodyStyle} id="section-1">
        <section style={helpSectionStyle}>
          <h2 style={sectionHeaderBump}>Navigating the App</h2>
          <hr style={{ marginBottom: "20px" }} />
          <p style={spacing}>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid
            saepe dolores vero sed deleniti sit, consequuntur, illo eos earum,
            itaque incidunt iure fugit beatae neque distinctio nisi
            necessitatibus quas esse. Lorem, ipsum dolor sit amet consectetur
            adipisicing elit. Facere maxime blanditiis dolorum enim cumque nisi,
            vel error placeat nemo sed aperiam vitae minus voluptatibus
            necessitatibus ab cum sunt fuga iusto, ipsa porro id rerum
            voluptates quia veniam? Ad cupiditate eius odit mollitia ut, laborum
            ullam nisi sapiente sint unde quidem quisquam facere cumque beatae
            omnis optio quibusdam, consequuntur aperiam nemo magni, assumenda
            commodi. Consequatur praesentium debitis repudiandae accusamus
            cupiditate perspiciatis, fuga architecto, excepturi sapiente
            eligendi reiciendis rerum expedita atque? Ab eaque praesentium eum
            quidem, quam, vitae animi obcaecati temporibus culpa fugiat aliquam
            assumenda accusamus!
          </p>
          <p style={spacing}>
            Temporibus nihil praesentium aliquid, tenetur dignissimos maxime?
            Soluta ducimus eveniet ut reiciendis hic ipsa quis culpa ratione eum
            sint reprehenderit voluptatibus esse repellat quos, ex repudiandae
            aliquam est iure consequuntur minus incidunt. Vero debitis atque
            quae quo a voluptatibus commodi error placeat. Aperiam voluptatum
            sit quia, repudiandae error dolorum officiis nisi consectetur
            pariatur obcaecati itaque dolor libero sapiente porro atque? Quam,
            corporis quisquam, deserunt molestiae explicabo quos cum est saepe
            recusandae optio repudiandae commodi hic! Commodi rem, cumque quod
            quasi, maiores eum perferendis aut, blanditiis quae laudantium sequi
            odit voluptate? Repudiandae unde ipsa, odit ipsam sed tempora
            dolorum ducimus in veritatis quo magni adipisci, tenetur ea deserunt
            recusandae obcaecati quas, magnam rerum sint nihil! Debitis
            repellat, quam modi in dolore atque animi ad aut quidem aperiam
            expedita exercitationem, libero veniam eos! Eum nisi itaque, dolore
            minus possimus iste? Tempora porro et voluptatem perspiciatis
            voluptas distinctio eos pariatur incidunt odio quo molestiae
            provident suscipit, sint accusamus asperiores accusantium illo nulla
            nisi ratione atque.
          </p>
          <p style={spacing}>
            Excepturi aspernatur iure temporibus laudantium optio, accusantium
            consectetur eius commodi quibusdam incidunt explicabo dignissimos
            dolor placeat esse quidem ratione eos harum consequuntur! Cum
            quibusdam incidunt, recusandae quia eaque perferendis totam
            voluptate earum dicta, mollitia illo beatae repellat velit magnam
            accusamus eveniet voluptatum sint pariatur? Nulla iure ipsam est
            dicta accusantium expedita quod provident, voluptates reprehenderit
            reiciendis laboriosam possimus nesciunt sint. Neque, qui placeat?
            Omnis cum itaque nihil, dolore earum perspiciatis libero aliquam,
            sunt tempore consectetur rem sint modi molestiae eaque minima animi
            quibusdam delectus atque. Temporibus sed doloribus tempora
            cupiditate explicabo qui voluptatem dolores a hic voluptatibus
            molestias magni error, omnis accusantium laborum earum pariatur
            corporis ea optio ducimus nostrum ex provident laudantium! Voluptas,
            laborum consectetur quaerat atque rerum nemo sequi aspernatur
            aliquid. Odio dicta temporibus commodi exercitationem possimus animi
            mollitia nemo reprehenderit voluptates impedit, aperiam vel, unde
            ducimus. Quo rem eos exercitationem reiciendis.
          </p>
        </section>
        <div id="section-2" style={{ padding: "40px" }}></div>
        <section style={helpSectionStyle}>
          <h2 style={sectionHeaderBump}>Storing your collection</h2>
          <hr style={{ marginBottom: "20px" }} />
          <p style={spacing}>
            Temporibus nihil praesentium aliquid, tenetur dignissimos maxime?
            Soluta ducimus eveniet ut reiciendis hic ipsa quis culpa ratione eum
            sint reprehenderit voluptatibus esse repellat quos, ex repudiandae
            aliquam est iure consequuntur minus incidunt. Vero debitis atque
            quae quo a voluptatibus commodi error placeat. Aperiam voluptatum
            sit quia, repudiandae error dolorum officiis nisi consectetur
            pariatur obcaecati itaque dolor libero sapiente porro atque? Quam,
            corporis quisquam, deserunt molestiae explicabo quos cum est saepe
            recusandae optio repudiandae commodi hic! Commodi rem, cumque quod
            quasi, maiores eum perferendis aut, blanditiis quae laudantium sequi
            odit voluptate? Repudiandae unde ipsa, odit ipsam sed tempora
            dolorum ducimus in veritatis quo magni adipisci, tenetur ea deserunt
            recusandae obcaecati quas, magnam rerum sint nihil! Debitis
            repellat, quam modi in dolore atque animi ad aut quidem aperiam
            expedita exercitationem, libero veniam eos! Eum nisi itaque, dolore
            minus possimus iste? Tempora porro et voluptatem perspiciatis
            voluptas distinctio eos pariatur incidunt odio quo molestiae
            provident suscipit, sint accusamus asperiores accusantium illo nulla
            nisi ratione atque.
          </p>
        </section>
        <section style={helpSectionStyle}>
          <h2 style={sectionHeaderBump}>Sharing your collection</h2>
          <hr style={{ marginBottom: "20px" }} />
          <p style={spacing}>
            Temporibus nihil praesentium aliquid, tenetur dignissimos maxime?
            Soluta ducimus eveniet ut reiciendis hic ipsa quis culpa ratione eum
            sint reprehenderit voluptatibus esse repellat quos, ex repudiandae
            aliquam est iure consequuntur minus incidunt. Vero debitis atque
            quae quo a voluptatibus commodi error placeat. Aperiam voluptatum
            sit quia.
          </p>
        </section>
        <section style={helpSectionStyle}>
          <h2 style={sectionHeaderBump}>About the latest version</h2>
          <hr style={{ marginBottom: "20px" }} />
          <p style={spacing}>
            Temporibus nihil praesentium aliquid, tenetur dignissimos maxime?
            Soluta ducimus eveniet ut reiciendis hic ipsa quis culpa ratione eum
            sint reprehenderit voluptatibus esse repellat quos, ex repudiandae
            aliquam est iure consequuntur minus incidunt. Vero debitis atque
            quae quo a voluptatibus commodi error placeat. Aperiam voluptatum
            sit quia.
          </p>
        </section>
        <div id="privacy" style={{ padding: "40px" }}></div>
        <h1>Privacy Policy</h1>
        <section style={helpSectionStyle}>
          <h2 style={sectionHeaderBump}>100% secure and never share info</h2>
          <hr style={{ marginBottom: "20px" }} />
          <p style={spacing}>
            Temporibus nihil praesentium aliquid, tenetur dignissimos maxime?
            Soluta ducimus eveniet ut reiciendis hic ipsa quis culpa ratione eum
            sint reprehenderit voluptatibus esse repellat quos, ex repudiandae
            aliquam est iure consequuntur minus incidunt. Vero debitis atque
            quae quo a voluptatibus commodi error placeat. Aperiam voluptatum
            sit quia, repudiandae error dolorum officiis nisi consectetur
            pariatur obcaecati itaque dolor libero sapiente porro atque? Quam,
            corporis quisquam, deserunt molestiae explicabo quos cum est saepe
            recusandae optio repudiandae commodi hic! Commodi rem, cumque quod
            quasi, maiores eum perferendis aut, blanditiis quae laudantium sequi
            odit voluptate?
          </p>
          <p style={spacing}>
            Repudiandae unde ipsa, odit ipsam sed tempora dolorum ducimus in
            veritatis quo magni adipisci, tenetur ea deserunt recusandae
            obcaecati quas, magnam rerum sint nihil! Debitis repellat, quam modi
            in dolore atque animi ad aut quidem aperiam expedita exercitationem,
            libero veniam eos! Eum nisi itaque, dolore minus possimus iste?
            Tempora porro et voluptatem perspiciatis voluptas distinctio eos
            pariatur incidunt odio quo molestiae provident suscipit, sint
            accusamus asperiores accusantium illo nulla nisi ratione atque.
          </p>
          <p style={spacing}>
            Temporibus nihil praesentium aliquid, tenetur dignissimos maxime?
            Soluta ducimus eveniet ut reiciendis hic ipsa quis culpa ratione eum
            sint reprehenderit voluptatibus esse repellat quos, ex repudiandae
            aliquam est iure consequuntur minus incidunt. Vero debitis atque
            quae quo a voluptatibus commodi error placeat. Aperiam voluptatum
            sit quia, repudiandae error dolorum officiis nisi consectetur
            pariatur obcaecati itaque dolor libero sapiente porro atque? Quam,
            corporis quisquam, deserunt molestiae explicabo quos cum est saepe
            recusandae optio repudiandae commodi hic! Commodi rem, cumque quod
            quasi, maiores eum perferendis aut, blanditiis quae laudantium sequi
            odit voluptate?
          </p>
          <p style={spacing}>
            Repudiandae unde ipsa, odit ipsam sed tempora dolorum ducimus in
            veritatis quo magni adipisci, tenetur ea deserunt recusandae
            obcaecati quas, magnam rerum sint nihil! Debitis repellat, quam modi
            in dolore atque animi ad aut quidem aperiam expedita exercitationem,
            libero veniam eos! Eum nisi itaque, dolore minus possimus iste?
            Tempora porro et voluptatem perspiciatis voluptas distinctio eos
            pariatur incidunt odio quo molestiae provident suscipit, sint
            accusamus asperiores accusantium illo nulla nisi ratione atque.
          </p>
          <p style={spacing}>
            Temporibus nihil praesentium aliquid, tenetur dignissimos maxime?
            Soluta ducimus eveniet ut reiciendis hic ipsa quis culpa ratione eum
            sint reprehenderit voluptatibus esse repellat quos, ex repudiandae
            aliquam est iure consequuntur minus incidunt. Vero debitis atque
            quae quo a voluptatibus commodi error placeat. Aperiam voluptatum
            sit quia, repudiandae error dolorum officiis nisi consectetur
            pariatur obcaecati itaque dolor libero sapiente porro atque? Quam,
            corporis quisquam, deserunt molestiae explicabo quos cum est saepe
            recusandae optio repudiandae commodi hic! Commodi rem, cumque quod
            quasi, maiores eum perferendis aut, blanditiis quae laudantium sequi
            odit voluptate?
          </p>
          <p style={spacing}>
            Repudiandae unde ipsa, odit ipsam sed tempora dolorum ducimus in
            veritatis quo magni adipisci, tenetur ea deserunt recusandae
            obcaecati quas, magnam rerum sint nihil! Debitis repellat, quam modi
            in dolore atque animi ad aut quidem aperiam expedita exercitationem,
            libero veniam eos! Eum nisi itaque, dolore minus possimus iste?
            Tempora porro et voluptatem perspiciatis voluptas distinctio eos
            pariatur incidunt odio quo molestiae provident suscipit, sint
            accusamus asperiores accusantium illo nulla nisi ratione atque.
          </p>
          <p style={spacing}>
            Temporibus nihil praesentium aliquid, tenetur dignissimos maxime?
            Soluta ducimus eveniet ut reiciendis hic ipsa quis culpa ratione eum
            sint reprehenderit voluptatibus esse repellat quos, ex repudiandae
            aliquam est iure consequuntur minus incidunt. Vero debitis atque
            quae quo a voluptatibus commodi error placeat. Aperiam voluptatum
            sit quia, repudiandae error dolorum officiis nisi consectetur
            pariatur obcaecati itaque dolor libero sapiente porro atque? Quam,
            corporis quisquam, deserunt molestiae explicabo quos cum est saepe
            recusandae optio repudiandae commodi hic! Commodi rem, cumque quod
            quasi, maiores eum perferendis aut, blanditiis quae laudantium sequi
            odit voluptate?
          </p>
          <p style={spacing}>
            Repudiandae unde ipsa, odit ipsam sed tempora dolorum ducimus in
            veritatis quo magni adipisci, tenetur ea deserunt recusandae
            obcaecati quas, magnam rerum sint nihil! Debitis repellat, quam modi
            in dolore atque animi ad aut quidem aperiam expedita exercitationem,
            libero veniam eos! Eum nisi itaque, dolore minus possimus iste?
            Tempora porro et voluptatem perspiciatis voluptas distinctio eos
            pariatur incidunt odio quo molestiae provident suscipit, sint
            accusamus asperiores accusantium illo nulla nisi ratione atque.
          </p>
          <p style={spacing}>
            Temporibus nihil praesentium aliquid, tenetur dignissimos maxime?
            Soluta ducimus eveniet ut reiciendis hic ipsa quis culpa ratione eum
            sint reprehenderit voluptatibus esse repellat quos, ex repudiandae
            aliquam est iure consequuntur minus incidunt. Vero debitis atque
            quae quo a voluptatibus commodi error placeat. Aperiam voluptatum
            sit quia, repudiandae error dolorum officiis nisi consectetur
            pariatur obcaecati itaque dolor libero sapiente porro atque? Quam,
            corporis quisquam, deserunt molestiae explicabo quos cum est saepe
            recusandae optio repudiandae commodi hic! Commodi rem, cumque quod
            quasi, maiores eum perferendis aut, blanditiis quae laudantium sequi
            odit voluptate?
          </p>
          <p style={spacing}>
            Repudiandae unde ipsa, odit ipsam sed tempora dolorum ducimus in
            veritatis quo magni adipisci, tenetur ea deserunt recusandae
            obcaecati quas, magnam rerum sint nihil! Debitis repellat, quam modi
            in dolore atque animi ad aut quidem aperiam expedita exercitationem,
            libero veniam eos! Eum nisi itaque, dolore minus possimus iste?
            Tempora porro et voluptatem perspiciatis voluptas distinctio eos
            pariatur incidunt odio quo molestiae provident suscipit, sint
            accusamus asperiores accusantium illo nulla nisi ratione atque.
          </p>
        </section>
      </div>
    </div>
  );
}

export default Help;
