import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { Outlet, Navigate } from "react-router-dom";
//import NavBar from "../components/NavBar";

function VendorRoutes() {
  const { userInformation } = useContext(UserContext);
  let isLoggedIn = userInformation.isLoggedIn;
  let isVendor = userInformation.userType;
  return isLoggedIn && isVendor === "vendor" ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default VendorRoutes;
