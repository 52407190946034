import { useContext, useState, useEffect } from "react";
import navBarStyle from "../styles/nav.module.scss";
import { MaterialContext } from "../context/MaterialContext";
import { closeIcon } from "./Icons";
import { useNavigate } from "react-router-dom";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, delay]);

  return debouncedValue;
}

function SearchBar() {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const navigate = useNavigate();
  const { search, changeSearch } = useContext(MaterialContext);

  const handleSearch = (e) => {
    if (navigate === "/library") {
      setSearchTerm(e.target.value);
    } else {
      navigate("/library");
      setSearchTerm(e.target.value);
    }
  };
  useEffect(() => {
    changeSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, changeSearch]);
  const handleClear = () => {
    changeSearch("");
    setSearchTerm("");
  };
  return (
    <div className={navBarStyle.search}>
      <input
        id="searchbar"
        name="searchbar-material"
        type="text"
        placeholder="search"
        value={searchTerm}
        onChange={handleSearch}
      />
      {search && (
        <span className={navBarStyle.clearSearch} onClick={handleClear}>
          {closeIcon}
        </span>
      )}
    </div>
  );
}

export default SearchBar;
