import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { Outlet, Navigate } from "react-router-dom";
import NavBar from "../components/NavBar";
//import SingleMaterial from "../pages/SingleMaterial";

function ProtectedRoutes() {
  //const { vendor, slug } = useParams();
  const { userInformation } = useContext(UserContext);
  let isLoggedIn = userInformation.isLoggedIn;

  return isLoggedIn ? (
    <>
      <NavBar />
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default ProtectedRoutes;
