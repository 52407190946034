import { useContext, useState, useEffect } from "react";
import inputStyle from "../../styles/input.module.scss";
import { MaterialContext } from "../../context/MaterialContext";
import axios from "axios";
import DragDrop from "./DragDrop";
import Dropzone from "./Dropzone";
import { closeIcon } from "../../components/Icons";
import PreviewSwatch from "./PreviewSwatch";

function ImageAdd({
  vendor,
  slug,
  data,
  setMaterialArray,
  materialDescription,
}) {
  const { ROOT_SERVER } = useContext(MaterialContext);
  const [images, setImages] = useState([]);
  const [folder, setFolder] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [uploadedImages, setUploadedImages] = useState(false);

  useEffect(() => {
    setMaterialArray(images);
  }, [images, setMaterialArray]);

  function getData() {
    let materialGet = `${ROOT_SERVER}material/${vendor}/${slug}`;
    let folderGet = `${ROOT_SERVER}material/folder/${vendor}/${slug}`;

    const requestMaterials = axios.get(materialGet);
    const requestFolder = axios.get(folderGet);

    axios
      .all([requestMaterials, requestFolder])
      .then(
        axios.spread((...responses) => {
          const responseMaterials = responses[0];
          const responseFolder = responses[1];
          console.log("responses", responseMaterials);

          setImages(responseMaterials.data[0].images);

          setPreviewImage(responseMaterials.data[0].images[0]);
          if (responseFolder.data.length) {
            setFolder(responseFolder.data);
          }
        })
      )
      .catch((errors) => {
        console.log(errors);
        // react on errors.
      });
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [data, uploadedImages]);

  useEffect(() => {
    if (uploadedImages) {
      getData();
    }
    // eslint-disable-next-line
  }, [uploadedImages]);

  const handleAddImageFromFolder = (image) => {
    const newValue = [...images, image];
    setImages(newValue);
  };
  const handleAlreadyInculded = () => {
    alert("its already included");
  };

  const handleDeleteFromFolder = (item) => {
    alert(`ARE YOU SURE YOU WANT TO DELETE ${item}`);
    axios
      .delete(
        `${ROOT_SERVER}upload/delete/${item}`,

        {
          headers: {
            "Content-Type": "application/json",
          },
          data: { vendor: vendor, slug: slug },
        }
      )
      .then(() => {
        getData();
      });
  };

  return (
    <>
      <div className={inputStyle.previewHeader}>
        <div className={inputStyle.swatchView}>
          <PreviewSwatch
            images={images}
            vendor={vendor}
            slug={slug}
            materialDescription={materialDescription}
          />
        </div>
        <div className={inputStyle.selectSwatchView}>
          {previewImage.length !== 0 && (
            <img
              className={inputStyle.droppedImage}
              src={`${ROOT_SERVER}${vendor}/${slug}/swatch/${previewImage}`}
              alt={"test swatch"}
            />
          )}
        </div>
      </div>
      <DragDrop
        images={images}
        vendor={vendor}
        slug={slug}
        setImages={setImages}
        setPreviewImage={setPreviewImage}
      />

      <div className={inputStyle.inputSwatchContainer}>
        <div className={inputStyle.inputSwatchHeader}>
          <div className={inputStyle.inputSwatchHeaderTitle}>FILES</div>
        </div>
        {folder?.map((item, i) => (
          <div key={item} className={inputStyle.dragDropItem}>
            {images.includes(item) ? null : (
              <div
                className={inputStyle.deleteDragDropItem}
                onClick={() => handleDeleteFromFolder(item)}
              >
                <div className={inputStyle.deleteIcon}>{closeIcon}</div>
              </div>
            )}
            <img
              src={`${ROOT_SERVER}${vendor}/${slug}/thumbnail/${item}`}
              alt={item + "folder" + i}
              onClick={() => setPreviewImage(item)}
            />
            <div
              className={inputStyle.folderItemAdd}
              onClick={
                !images.includes(item)
                  ? () => handleAddImageFromFolder(item)
                  : handleAlreadyInculded
              }
            >
              {images.includes(item) ? "In View" : "add"}
            </div>
          </div>
        ))}
      </div>
      <div className={inputStyle.inputSwatchContainer}>
        <div className={inputStyle.inputSwatchHeader}>
          <div className={inputStyle.inputSwatchHeaderTitle}>UPLOAD</div>
        </div>
        <Dropzone
          vendor={vendor}
          slug={slug}
          setUploadedImages={setUploadedImages}
        />
      </div>
    </>
  );
}

export default ImageAdd;
