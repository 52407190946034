import { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { Outlet, useSearchParams } from "react-router-dom";
import NavBar from "../components/NavBar";
import NavBarExt from "../components/NavBarExt";
import axios from "axios";
//import SingleMaterial from "../pages/SingleMaterial";

function ExternalRoutes() {
  //const { vendor, slug } = useParams();
  console.log("external route hit");
  const { userInformation } = useContext(UserContext);
  let isLoggedIn = userInformation.isLoggedIn;

  const [accessData, setAccessData] = useState([]);
  const [queryParameters] = useSearchParams();
  const access = queryParameters.get("access");

  const getAccess = (access) => {
    const request = { accessId: access };
    axios
      .post("http://localhost:5001/access", request)
      .then((response) => response)
      .then((res) => {
        setAccessData(res.data[0]);
      });
  };

  useEffect(() => {
    if (accessData.id) {
      console.log(accessData.title, accessData.description);
      console.log(accessData.materialArray);
    }
  }, [accessData]);

  useEffect(() => {
    if (access) {
      getAccess(access);
    }
    //eslint-disable-next-line
  }, []);

  return isLoggedIn ? (
    <>
      <NavBar />
      <Outlet />
    </>
  ) : (
    <>
      <NavBarExt />
      <Outlet />
    </>
  );
}

export default ExternalRoutes;
