import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
//import { UserContext } from "../contexts/UserContext";
import { MaterialContext } from "../context/MaterialContext";
import MaterialViewCount from "../utilities/MaterialViewCount";
import modalStyle from "../styles/modal.module.scss";
import { editIcon, closeIcon } from "./Icons";
import ImageDownload from "../utilities/ImageDownload";
import ReactGA from "react-ga4";
import MobileMaterialModal from "./mobile/mobileMaterialModal";

function MaterialModal({
  showModal,
  setShowModal,
  id,
  brandData,
  userInformation,
  media,
  imageNum,
}) {
  const navigate = useNavigate();
  const { materials, ROOT_SERVER, allMaterials } = useContext(MaterialContext);
  const [materialData, setMaterialData] = useState([]);
  const [viewing, setViewing] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageNumber, setImageNumber] = useState(imageNum);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [modalDimensions, setModalDimensions] = useState([]);
  const [imageDownload, setImageDownload] = useState(false);

  const getModalDimension = useRef(null);

  ReactGA.initialize([
    {
      trackingId: "G-J5KX239711",
    },
  ]);

  const sustainablityData = {
    detail: "only for cow hide asiatan",
    title: "sustainability",
    info: [
      "Asiatan Kind Leather",
      "Carbon Neutral Athletic Leather",
      "Common Crust Program",
      "90% Cutting Value for Reduced waste",
    ],
  };
  const splitData = {
    detail: "fasion splits",
    title: "Fashion Suede",
    info: ["Split based fashion leather"],
  };
  const tectuffData = {
    detail: "tectuff leather",
    title: "tectuff features",
    info: [
      "durable performance finish",
      "abrasion resistant",
      "water-based PU finish",
    ],
  };

  function infoBox(type, brand) {
    switch (type) {
      case "cowhide":
        return (
          <div className={modalStyle.infoBox}>
            <div style={{ width: "100%" }} className={modalStyle.infoContent}>
              <div className={modalStyle.infoContentHeader}>
                <h4>{sustainablityData.title}</h4>
              </div>
              <div className={modalStyle.infoContentBody}>
                {sustainablityData.info.map((item) => (
                  <h4 key={item}>{item}</h4>
                ))}
              </div>
            </div>
          </div>
        );
      case "split":
        if (brand === "tectuff") {
          return (
            <div className={modalStyle.infoBox}>
              <div style={{ width: "100%" }} className={modalStyle.infoContent}>
                <div className={modalStyle.infoContentHeader}>
                  <h4>{tectuffData.title}</h4>
                </div>
                <div className={modalStyle.infoContentBody}>
                  {tectuffData.info.map((item) => (
                    <h4 key={item}>{item}</h4>
                  ))}
                </div>
              </div>
            </div>
          );
        }
        return (
          <div className={modalStyle.infoBox}>
            <div style={{ width: "100%" }} className={modalStyle.infoContent}>
              <div className={modalStyle.infoContentHeader}>
                <h4>{splitData.title}</h4>
              </div>
              <div className={modalStyle.infoContentBody}>
                {splitData.info.map((item) => (
                  <h4 key={item}>{item}</h4>
                ))}
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className={modalStyle.infoBox}>
            <div style={{ width: "100%" }} className={modalStyle.infoContent}>
              <div className={modalStyle.infoContentHeader}>
                <h4>{sustainablityData.title}</h4>
              </div>
              <div className={modalStyle.infoContentBody}>
                {sustainablityData.info.map((item) => (
                  <h4 key={item}>{item}</h4>
                ))}
              </div>
            </div>
          </div>
        );
    }
  }

  useEffect(() => {
    if (id && showModal) {
      setError(false);
      const getMaterial = allMaterials.find((material) => material.id === id);
      if (getMaterial) {
        setMaterialData(getMaterial);
        setViewing(
          `${ROOT_SERVER}${getMaterial.vendor}/${getMaterial.slug}/${
            media === "mobile" ? "swatch" : "full"
          }/${getMaterial.images[imageNumber]}`
        );
        ReactGA.event({
          category: "liked-material",
          action: `${getMaterial.vendor} - ${getMaterial.slug}`,
        });
        body.style.overflow = "hidden";
        body.style.marginRight = "10px";
        setLoaded(true);
      } else {
        const getMaterial = allMaterials.find((material) => material.id === id);
        console.log("error in Material Modal", getMaterial);
        setError(true);
      }
    }
    // eslint-disable-next-line
  }, [materials, imageNumber]);

  useEffect(() => {
    MaterialViewCount(materialData.id);
  }, [materialData.id]);

  const body = document.querySelector("body");
  const handleCloseModal = () => {
    setShowModal(false);
    //setSingleLoader(false)
    body.style.overflow = "auto";
    body.style.marginRight = "0px";
  };
  const handleImageLoaded = () => {
    setImageLoaded(true);
    setModalDimensions([
      getModalDimension?.current?.clientHeight,
      getModalDimension?.current?.clientWidth,
    ]);
  };

  window.addEventListener(
    "resize",
    function () {
      setTimeout(() => {
        setModalDimensions([
          getModalDimension?.current?.clientHeight,
          getModalDimension?.current?.clientWidth,
        ]);
      }, 200);
    },
    true
  );

  const handleChangeImage = (i) => {
    if (i !== imageNumber) {
      setImageLoaded(false);
      setImageNumber(i);
    }
  };
  const handleSingle = (vendor, slug) => {
    navigate(`/${vendor}/${slug}`);
    body.style.overflow = "auto";
  };
  const handleEdit = (vendor, slug) => {
    navigate(`../material/edit/${vendor}/${slug}`);
    body.style.overflow = "auto";
  };

  const handleDownloadImage = (vendor, slug) => {
    ImageDownload(viewing, vendor, slug);
    setImageDownload(true);
    setTimeout(() => {
      setImageDownload(false);
    }, 3000);
  };

  const {
    title,
    slug,
    description,
    price,
    status,
    type,
    subtype,
    images,
    substrate,
    vendor,
    character,
    feature,
  } = materialData;

  if (media === "mobile") {
    return (
      <MobileMaterialModal
        handleCloseModal={handleCloseModal}
        viewing={viewing}
        vendor={vendor}
        title={title}
        handleImageLoaded={handleImageLoaded}
        imageLoaded={imageLoaded}
        description={description}
        slug={slug}
        images={images}
        materialData={materialData}
        imageNumber={imageNumber}
        handleChangeImage={handleChangeImage}
        ROOT_SERVER={ROOT_SERVER}
        modalDimensions={modalDimensions}
        loaded={loaded}
      />
    );
  }

  return (
    <>
      {showModal && !error ? (
        <div className={modalStyle.modalShade} onClick={handleCloseModal}>
          {loaded ? (
            <div
              className={modalStyle.materialModal}
              onClick={(e) => e.stopPropagation()}
              ref={getModalDimension}
            >
              <div className={modalStyle.modalContent}>
                {/* IMAGE CONTAINER START */}
                <div
                  className={modalStyle.imageContainer}
                  style={{ height: modalDimensions[0] }}
                >
                  {!imageLoaded && <div className="spinner" />}
                  <img
                    className={modalStyle.materialImage}
                    src={viewing}
                    alt={`${vendor} - ${title}`}
                    onLoad={handleImageLoaded}
                    style={
                      imageLoaded
                        ? { filter: "blur(0px)" }
                        : { filter: "blur(6px)" }
                    }
                  />
                  <div className={modalStyle.imageSelector}>
                    {images.length >= 2 &&
                      images?.map((image, i) => (
                        <div
                          key={image + " -- " + i}
                          data-image={image + " -- " + i}
                          className={`${modalStyle.swatch} ${
                            i === imageNumber && modalStyle.active
                          }`}
                          style={{
                            overflow: "hidden",
                          }}
                          onClick={() => handleChangeImage(i)}
                        >
                          <img
                            src={`${ROOT_SERVER}${vendor}/${slug}/thumbnail/${image}`}
                            alt={slug + i}
                          />
                        </div>
                      ))}
                  </div>
                </div>
                {/* IMAGE CONTAINER END */}
                {/* DATA CONTAINER START */}
                <div
                  className={modalStyle.dataContainer}
                  style={{ height: modalDimensions[0] }}
                >
                  <div
                    className={modalStyle.closeButton}
                    onClick={handleCloseModal}
                  >
                    <span>close</span> {closeIcon}
                  </div>
                  <div className={modalStyle.dataContainerTop}>
                    <h3 style={{ textTransform: "uppercase" }}>{vendor}</h3>
                    <h1>{title}</h1>
                    <h3>{description}</h3>
                    {/* INFO BOX START >> PRICE & MATERIAL DATA */}
                    <div className={modalStyle.infoBox}>
                      <div className={modalStyle.infoContent}>
                        <div className={modalStyle.infoContentHeader}>
                          <h4>Price</h4>
                        </div>
                        <div className={modalStyle.infoContentBody}>
                          <div className={modalStyle.price}>
                            <div className={modalStyle.value}>
                              ${parseFloat(price).toFixed(2)}
                            </div>
                            <div className={modalStyle.uom}>/ sq.ft.</div>
                            <span className={modalStyle.disclaimer}>
                              Price for reference only.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={modalStyle.infoContent}>
                        <div className={modalStyle.infoContentHeader}>
                          <h4>Material Data</h4>
                        </div>
                        <div className={modalStyle.infoContentBody}>
                          <h6>Type: {substrate}</h6>
                          <h6>Subtype: {type}</h6>
                          <h6>Group: {subtype}</h6>
                          <h6>Status: {status}</h6>
                        </div>
                      </div>
                    </div>
                    {/* INFO BOX START >> Character & Finish */}
                    <div className={modalStyle.infoBox}>
                      <div className={modalStyle.infoContent}>
                        <div className={modalStyle.infoContentHeader}>
                          <h4>CHARACTER</h4>
                        </div>
                        <div className={modalStyle.infoContentBody}>
                          <h4>{character[0]} temper</h4>
                          <h4>{character[1]}</h4>
                        </div>
                      </div>
                      <div className={modalStyle.infoContent}>
                        <div className={modalStyle.infoContentHeader}>
                          <h4>Finish</h4>
                        </div>
                        <div className={modalStyle.infoContentBody}>
                          {feature?.map((fin, i) => (
                            <h4 key={fin + i}>{fin}</h4>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* END CHARACTER & FINISH */}
                    {/* INFO BOX START >> SUSTAINABILITY */}
                    {infoBox(type, vendor)}
                    {/* END SUSTAINABILITY */}
                    {/* INFO BOX START >> Brand Data */}
                    {brandData.length > 0 && (
                      <div className={modalStyle.infoBox}>
                        <div
                          style={{ width: "100%" }}
                          className={modalStyle.infoContent}
                        >
                          <div className={modalStyle.infoContentHeader}>
                            <h4>Nike Data</h4>
                          </div>
                          <div className={modalStyle.infoContentBody}>
                            {brandData.map((data, i) => (
                              <h4 key={data.pcx_material_number + i}>
                                {title} - {data.thickness}mm - PCX#:
                                {data.pcx_material_number}
                              </h4>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* END Brand data */}
                    {/* Start Edit data */}
                    {userInformation.userType === "vendor" && (
                      <div className={modalStyle.infoBox}>
                        <div className={modalStyle.infoContent}>
                          <div
                            className={`${modalStyle.infoContentBody} ${modalStyle.editButton}`}
                            onClick={() => handleEdit(vendor, slug)}
                          >
                            {editIcon}
                            <h4 className={modalStyle.pageLink}>
                              Edit {title}
                            </h4>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* End Edit data */}
                  </div>
                  <div className={modalStyle.dataContainerBottom}>
                    <div
                      className={modalStyle.bottomButton}
                      onClick={() => handleSingle(vendor, slug)}
                    >
                      <h4 className={modalStyle.pageLink}>
                        More like this article
                      </h4>
                    </div>
                    {!imageDownload ? (
                      <div
                        className={modalStyle.bottomButton}
                        onClick={() => handleDownloadImage(vendor, slug)}
                      >
                        <h4>Download Image</h4>
                      </div>
                    ) : (
                      <div className={modalStyle.bottomButton}>
                        <h4>Downloading</h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>Loading it up</div>
          )}
        </div>
      ) : (
        <div className="error-modal">Ooops! Something went wrong</div>
      )}
    </>
  );
}

export default MaterialModal;
