import { useNavigate } from "react-router-dom";
function Footer({ path }) {
  const navigate = useNavigate();

  const Contact = () => {
    switch (path) {
      case "/":
        return (
          <div className="footer-contact" onClick={() => navigate("/help")}>
            HELP
          </div>
        );
      case "/help":
        return null;

      default:
        return (
          <div className="footer-contact" onClick={() => navigate("/contact")}>
            CONTACT
          </div>
        );
    }
  };

  return (
    <div className="footer-bar">
      <div className="footer-copy">MATLIB 2023</div>
      <Contact />
    </div>
  );
}

export default Footer;
