import { useEffect, useState } from "react";
import axios from "axios";
import fuzzysort from "fuzzysort";
import { useNavigate } from "react-router-dom";
import { closeIcon, searchIcon } from "../../components/Icons";
//import * as XLSX from "xlsx";

function MaterialPrice() {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [changed, setChanged] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const navigate = useNavigate();
  const URL = "https://node.matlib.net/material";

  useEffect(() => {
    fetch(URL)
      .then((res) => res.json())
      .then((response) => setAllData(response));
  }, []);

  useEffect(() => {
    if (allData.length) {
      const optionsFuzzy = {
        limit: 100, // don't return more results than you need!
        allowTypo: true, // if you don't care about allowing typos
        threshold: -10000, // don't return bad results
        keys: ["title", "price", "vendor", "type", "subtype"],
      };
      const resultsFuzzy = fuzzysort.go(filterValue, allData, optionsFuzzy);
      const materialList = resultsFuzzy.map((res) => res.obj);
      setData(materialList.length ? materialList : allData);
    }
  }, [allData, filterValue]);

  const handleGatherData = () => {
    const gatherData = JSON.stringify(data);
    console.log(gatherData);
  };
  function daysAgo(n) {
    //const modified_date = n.toLocalString("en-GB");

    const date1 = new Date(n);
    const date2 = new Date();

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays;
  }

  const handlePriceChange = (e, id) => {
    const newState = data.map((obj) => {
      // 👇️ if id equals 2, update country property
      console.log(obj);

      if (obj.id === id) {
        return { ...obj, price: e.target.value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    //console.log(...changed, [id]);
    if (!changed.includes(id)) {
      setChanged((prevState) => [...prevState, id]);
    }
    setData(newState);
  };
  const handleUpdateServer = (id, price) => {
    axios
      .put(`https://node.matlib.net/material/update/price/${id}`, {
        price: price,
      })
      .then(
        (response) => {
          console.log(response);
          if (changed.includes(id)) {
            var remove = changed.filter((item) => item !== id);
            //console.log(remove);
            setChanged(remove);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const handleRevert = (id) => {
    const newState = allData.map((obj) => {
      // 👇️ if id equals 2, update country property
      if (obj.id === id) {
        return { ...obj, price: obj.price };
      }
      // 👇️ otherwise return object as is
      return obj;
    });
    //console.log(...changed, [id]);
    if (changed.includes(id)) {
      var remove = changed.filter((item) => item !== id);
      //console.log(remove);
      setChanged(remove);
    }
    setData(newState);
  };
  const handleFilter = (e) => {
    e.preventDefault();
    setFilterValue(e.target.value);
  };
  const handleClearFilter = () => {
    setFilterValue("");
  };

  return (
    <div className="content pricingPage">
      <div className="count">
        <div>{data?.length}</div>
        <div style={{ fontSize: "8px" }}>articles</div>
      </div>
      <button
        style={{ position: "absolute", height: "60px", zIndex: 2 }}
        className="button-highlight"
        onClick={handleGatherData}
      >
        Gather Data
      </button>
      <div className="search-bar">
        <div className="search-icon">
          {filterValue.length >= 1 ? (
            <div className="closeIcon" onClick={handleClearFilter}>
              {closeIcon}
            </div>
          ) : (
            <div className="searchIcon">{searchIcon}</div>
          )}
        </div>
        <input
          id="filter"
          name="filter"
          type="text"
          onChange={handleFilter}
          value={filterValue}
          placeholder="search"
        />
      </div>
      <div className="table-area">
        <table className="table">
          <tbody className="table-body">
            <tr className="table-head">
              <th style={{ width: "20px" }}>No.</th>
              <th style={{ width: "40px" }}>company</th>
              <th>Name</th>
              <th>Character</th>
              <th>Feature</th>
              <th style={{ width: "200px" }}>Price</th>
              <th style={{ width: "80px" }}>Type</th>
              <th style={{ width: "200px" }}>Updated</th>
              <th style={{ width: "200px" }}>Modified</th>
            </tr>
            {data?.map((item, i) => (
              <tr key={item.id}>
                <td>{i}</td>
                <td>{item.vendor}</td>
                <td>{item.title}</td>
                <td>
                  {item.character.join(", ")}{" "}
                  <button
                    onClick={() =>
                      navigate(`../material/edit/${item.vendor}/${item.slug}`)
                    }
                  >
                    edit
                  </button>
                </td>
                <td>
                  {item.feature.join(", ")}{" "}
                  <button
                    onClick={() =>
                      navigate(`../material/edit/${item.vendor}/${item.slug}`)
                    }
                  >
                    edit
                  </button>
                </td>
                <td className="price-column">
                  <input
                    id={`price-${i}`}
                    className="price-input"
                    type="text"
                    value={item.price}
                    onChange={(e) => handlePriceChange(e, item.id)}
                  />
                </td>
                <td style={{ textAlign: "center" }}>{item.type}</td>
                <td style={{ textAlign: "center" }}>
                  {changed.includes(item.id) ? (
                    <>
                      <button
                        onClick={() => handleUpdateServer(item.id, item.price)}
                      >
                        Update
                      </button>
                      <button onClick={() => handleRevert(item.id)}>
                        Cancel
                      </button>
                    </>
                  ) : (
                    "no"
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  <h4>modified {daysAgo(item.date_modified)} ago</h4>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MaterialPrice;
