import React from "react";

function Playground() {
  return (
    <div className="content">
      <h2>Playground</h2>
    </div>
  );
}

export default Playground;
