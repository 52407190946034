import { useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { UserContext } from "../context/UserContext";
//import { MaterialContext } from "../context/MaterialContext";

import navBarStyle from "../styles/nav.module.scss";
//import FilterBox from "./FilterBox";
//import UserBox from "./UserBox";
//import { designerIcon, vendorIcon, guestIcon } from "./Icons";
//import SearchBar from "./SearchBar";

function NavBarExt() {
  const { collection } = useContext(UserContext);
  // const { isFilter, changeIsFilter, refetch, search } =
  //   useContext(MaterialContext);
  // const [userMenuIsVisible, setUserMenuIsVisible] = useState(false);
  // const [filterMenuIsVisible, setFilterMenuIsVisible] = useState(false);
  const navigate = useNavigate();
  //const location = useLocation();

  // const userType = (type) => {
  //   if (type === "vendor") {
  //     return vendorIcon;
  //   }
  //   if (type === "designer") {
  //     return designerIcon;
  //   }
  //   if (type === "guest") {
  //     return guestIcon;
  //   }
  // };
  //   useEffect(() => {
  //     if (navigate !== "/library") {
  //       setUserMenuIsVisible(false);
  //       setFilterMenuIsVisible(false);
  //     }
  //   }, [navigate]);

  const handleClickAction = (navigateTo) => {
    navigate(navigateTo);
  };

  //   const handleDropDownMenu = (type) => {
  //     if (type === "user") {
  //       setUserMenuIsVisible(!userMenuIsVisible);
  //       setFilterMenuIsVisible(false);
  //     }
  //     if (type === "filter") {
  //       setUserMenuIsVisible(false);
  //       setFilterMenuIsVisible(!filterMenuIsVisible);
  //     }
  //   };

  //   if (media === "mobile") {
  //     return (
  //       <>
  //         <FilterBox
  //           isFilter={isFilter}
  //           changeIsFilter={changeIsFilter}
  //           filterMenuIsVisible={filterMenuIsVisible}
  //           setFilterMenuIsVisible={setFilterMenuIsVisible}
  //         />
  //         <UserBox
  //           userInformation={userInformation}
  //           changelogOutUser={changelogOutUser}
  //           userMenuIsVisible={userMenuIsVisible}
  //           setUserMenuIsVisible={setUserMenuIsVisible}
  //           refetch={refetch}
  //         />
  //         <div className={navBarStyle.bar}>
  //           <div
  //             onClick={() => handleClickAction("library")}
  //             className={navBarStyle.logo}
  //           >
  //             MATLIB
  //           </div>
  //           <SearchBar />
  //           <div className={navBarStyle.navigation}>
  //             <div
  //               className={
  //                 !userMenuIsVisible
  //                   ? `${navBarStyle.userMenu}`
  //                   : `${navBarStyle.userMenu} ${navBarStyle.active}`
  //               }
  //               onClick={() => handleDropDownMenu("user")}
  //             >
  //               {userType(userInformation.userType)}
  //             </div>
  //           </div>
  //         </div>
  //       </>
  //     );
  //   }

  return (
    <>
      {/* <FilterBox
        isFilter={isFilter}
        changeIsFilter={changeIsFilter}
        filterMenuIsVisible={filterMenuIsVisible}
        setFilterMenuIsVisible={setFilterMenuIsVisible}
      />
      <UserBox
        userInformation={userInformation}
        changelogOutUser={changelogOutUser}
        userMenuIsVisible={userMenuIsVisible}
        setUserMenuIsVisible={setUserMenuIsVisible}
        refetch={refetch}
      /> */}
      <div className={navBarStyle.bar}>
        <div
          onClick={() => handleClickAction("library")}
          className={navBarStyle.logo}
        >
          MATLIB
        </div>
        {/* <SearchBar /> */}
        <div className={navBarStyle.navigation}>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "inactive")}
            to={`collection`}
          >
            COLLECTION{" "}
            <span className={navBarStyle.collectionCount}>
              {collection?.length}
            </span>
          </NavLink>
          {/* {location.pathname === "/library" && (
            <div
              className={
                !filterMenuIsVisible
                  ? `${navBarStyle.filter}`
                  : `${navBarStyle.filter} ${navBarStyle.active}`
              }
              onClick={() => handleDropDownMenu("filter")}
            >
              {isFilter ? <div>FILTERED</div> : <div>FILTER</div>}
              {downArrowIcon}
              {search.length >= 1 && isFilter ? (
                <span
                  style={{
                    position: "absolute",
                    bottom: "18px",
                    fontSize: "8px",
                    textTransform: "uppercase",
                  }}
                >
                  More options available
                </span>
              ) : null}
            </div>
          )}
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "inactive")}
            to={`library`}
          >
            LIBRARY
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "inactive")}
            to={`collection`}
          >
            COLLECTION{" "}
            <span className={navBarStyle.collectionCount}>
              {collection?.length}
            </span>
          </NavLink>
          <div
            className={
              !userMenuIsVisible
                ? `${navBarStyle.userMenu}`
                : `${navBarStyle.userMenu} ${navBarStyle.active}`
            }
            onClick={() => handleDropDownMenu("user")}
          >
            {userType(userInformation.userType)}
          </div> */}
        </div>
      </div>
    </>
  );
}

export default NavBarExt;
