import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { threeBarsIcon, trashIcon } from "../Icons";
import "../../App.scss";

export default function MultiDragDrop({
  compileResults,
  setCompileResults,
  handleRemoveItem,
  notes,
  setNotes,
}) {
  // const [materials, setMaterials] = useState(articles);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // reorder using index of source and destination.
    const schemaCopy = compileResults.slice();
    const [removed] = schemaCopy.splice(result.source.index, 1);
    // put the removed one into destination.
    schemaCopy.splice(result.destination.index, 0, removed);

    // console.log(schemaCopy);

    //setMaterials(schemaCopy);
    setCompileResults(schemaCopy);
  };
  const handleAddNote = (e, i) => {
    setNotes(e.target.value, i);
  };
  return (
    <div
      style={{
        overflowX: "scroll",
        overflowY: "none",
        maxHeight: "500px",
        width: "470px",
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="column1">
          {(provided, snap) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {compileResults.map((it, i) => (
                <Draggable key={it.slug} draggableId={it.slug} index={i}>
                  {(provided, snap) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="list-item"
                      style={{
                        backgroundColor: snap.isDragging
                          ? "#22222260"
                          : "#22222290",
                        padding: "2px",
                        border: "1px solid #111",
                        margin: "1px 0",
                        color: "#FEFEFE",
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        maxWidth: "450px",
                        position: "relative",
                        minHeight: "102px",
                        userSelect: "none",

                        ...provided.draggableProps.style,
                      }}
                    >
                      {it.slug !== "note" ? (
                        <>
                          <img
                            src={`http://node.matlib.net/${it.vendor}/${it.slug}/thumbnail/${it.image}`}
                            alt={it.slug}
                          />
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p
                              style={{
                                textTransform: "uppercase",
                                fontSize: "12px",
                              }}
                            >
                              {it.title}
                            </p>
                            <p style={{ fontSize: "10px" }}>{it.description}</p>
                            <p style={{ fontSize: "10px" }}> {it.id}</p>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              zIndex: 1,
                              color: "#00ffed70",
                              right: 50,
                            }}
                            onClick={() => handleRemoveItem(it.id)}
                          >
                            {trashIcon}
                          </div>
                          <div
                            className="handle"
                            style={{
                              width: "40px",
                              height: "100%",
                              position: "absolute",
                              right: 0,
                              top: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {threeBarsIcon}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <textarea
                              type="text"
                              name={`interstitial-note-${i}`}
                              style={{
                                resize: "none",
                                height: "70px",
                                backgroundColor: "transparent",
                                color: "#fefefe",
                                outline: "none",
                                border: "thin solid #FEFEFE20",
                                borderBottom: "2px solid #FEFEFE",
                                padding: "5px",
                                width: "365px",
                              }}
                              placeholder="type your note here"
                              onChange={(e) => handleAddNote(e, i)}
                              value={notes}
                            />
                          </div>
                          <div
                            className="handle"
                            style={{
                              width: "40px",
                              height: "100%",
                              position: "absolute",
                              right: 0,
                              top: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {threeBarsIcon}
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              zIndex: 1,
                              color: "#00ffed70",
                              right: 50,
                            }}
                            onClick={() => handleRemoveItem(it.id)}
                          >
                            {trashIcon}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
