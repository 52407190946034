export default function UserMenuOptions(userType) {
  if (userType === "vendor") {
    return [
      {
        id: 0,
        buttonName: "Add/Edit Materials",
        buttonAction: "./material/add",
      },
      { id: 1, buttonName: "Your Collection", buttonAction: "./collection" },
      { id: 2, buttonName: "Price Update", buttonAction: "./material/price" },
    ];
  }
  if (userType === "designer") {
    return [
      { id: 0, buttonName: "Collection", buttonAction: "./collection" },
      { id: 1, buttonName: "Library", buttonAction: "./library" },
      { id: 2, buttonName: "Contact", buttonAction: "./contact" },
      { id: 3, buttonName: "help", buttonAction: "./help" },
    ];
  }
  if (userType === "guest") {
    return [
      { id: 0, buttonName: "Login", buttonAction: "./" },
      { id: 1, buttonName: "Contact", buttonAction: "./contact" },
      { id: 2, buttonName: "help", buttonAction: "./help" },
    ];
  }
}
