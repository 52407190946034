import { useEffect, useState, useContext } from "react";
import MaterialView from "../components/MaterialView";
//import MaterialModal from "../components/MaterialModal";

//import "swiper/swiper.min.css";
import { MaterialContext } from "../context/MaterialContext";
import { UserContext } from "../context/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import singleStyle from "../styles/single.module.scss";
import modalStyle from "../styles/modal.module.scss";
import FilteredLibrary from "../components/FilteredLibrary";
import MaterialViewCount from "../utilities/MaterialViewCount";
import ImageDownload from "../utilities/ImageDownload";
//import SwipeImages from "../components/SwipeImages";

import { editIcon, closeIcon } from "../components/Icons";

function SingleMaterial() {
  const { allMaterials, ROOT_SERVER } = useContext(MaterialContext);
  const { userInformation, media } = useContext(UserContext);
  const [materialData, setMaterialData] = useState([]);
  const [materialDataLoaded, setMaterialDataLoaded] = useState(false);
  const [imageNumber, setImageNumber] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [imageDownload, setImageDownload] = useState(false);
  const [viewing] = useState("");
  const [error, setError] = useState(false);
  //const [isSwiper, setIsSwiper] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const { vendor, slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getMaterialData = allMaterials.filter(
      (mat) => mat.vendor === vendor && mat.slug === slug
    );
    if (getMaterialData.length === 1) {
      setError(false);
      setMaterialData(getMaterialData[0]);
      setMaterialDataLoaded(true);
    } else {
      setError(true);
      setMaterialDataLoaded(false);
    }
    // eslint-disable-next-line
  }, [allMaterials, vendor, slug]);

  useEffect(() => {
    MaterialViewCount(materialData.id);
  }, [materialData.id]);

  useEffect(() => {
    if (materialDataLoaded) {
      setIsFailed(false);
      setLoaded(true);
      setImageLoaded(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [materialDataLoaded, slug, vendor, loaded]);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  const handleChangeImage = (i) => {
    if (i !== imageNumber) {
      setImageLoaded(false);
      setImageNumber(i);
    }
  };

  const handleEdit = (vendor, slug) => {
    navigate(`../material/edit/${vendor}/${slug}`);
  };

  const handleBackToLibrary = () => {
    navigate("/library");
  };

  const handleDownloadImage = (vendor, slug) => {
    ImageDownload(viewing, vendor, slug);
    setImageDownload(true);
    setTimeout(() => {
      setImageDownload(false);
    }, 3000);
  };

  const { userId, isLoggedIn } = userInformation;

  if (!userId && !isLoggedIn) {
    if (loaded && materialData && !isFailed) {
      const { id, title, subtype, character } = materialData;

      return (
        <div className="content">
          <MaterialView media={"laptop"} id={id} />
          <div className={singleStyle.singleSimilar}>
            <FilteredLibrary
              id={id}
              terms={character}
              title={title}
              allMaterials={allMaterials}
              control={subtype.toLowerCase()}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        {!isFailed && materialData ? (
          <div className="content">
            <div className="loaderUp">
              <h3>{vendor.replaceAll("-", " ").toUpperCase()}</h3>
              <h2>{slug.replaceAll("-", " ").toUpperCase()}</h2>
              <div className="spinner" />
            </div>
          </div>
        ) : (
          <div className="content">
            <div className="empty-collection">
              <div className="empty-collection-message">
                <div className="text-box">
                  <h2 className="login-view-heading">
                    Can't find {slug} from {vendor}
                  </h2>
                  <button className="button" onClick={handleBackToLibrary}>
                    return to library
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  if (loaded && materialData && !error) {
    const {
      id,
      title,
      slug,
      vendor,
      description,
      price,
      status,
      type,
      subtype,
      //softness,
      substrate,
      character,
      feature,
      view_count,
      images,
    } = materialData;

    return (
      <div className="content">
        <div
          className="materialContent"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className={modalStyle.materialModal}
            style={{ maxWidth: "calc(1020px + 430px)" }}
          >
            <div className={modalStyle.modalContent}>
              {/* IMAGE CONTAINER START */}
              <div className={modalStyle.imageContainer}>
                {!imageLoaded && <div className="spinner" />}
                <img
                  className={modalStyle.materialImage}
                  src={`${ROOT_SERVER}${materialData.vendor}/${
                    materialData.slug
                  }/${media === "mobile" ? "swatch" : "full"}/${
                    materialData.images[imageNumber]
                  }`}
                  alt={`${vendor} - ${title}`}
                  onLoad={handleImageLoaded}
                  style={
                    imageLoaded
                      ? { filter: "blur(0px)" }
                      : { filter: "blur(6px)" }
                  }
                />
                <div className={modalStyle.imageSelector}>
                  {images.length >= 2 &&
                    images?.map((image, i) => (
                      <div
                        key={image + " -- " + i}
                        data-image={image + " -- " + i}
                        className={`${modalStyle.swatch} ${
                          i === imageNumber && modalStyle.active
                        }`}
                        style={{
                          overflow: "hidden",
                        }}
                        onClick={() => handleChangeImage(i)}
                      >
                        <img
                          src={`${ROOT_SERVER}${vendor}/${slug}/thumbnail/${image}`}
                          alt={slug + i}
                        />
                      </div>
                    ))}
                </div>
              </div>
              {/* IMAGE CONTAINER END */}
              <div
                className={modalStyle.dataContainer}
                style={{ minWidth: "430px" }}
              >
                <div
                  className={modalStyle.closeButton}
                  onClick={handleBackToLibrary}
                >
                  <span>Close</span> {closeIcon}
                </div>
                <div className={modalStyle.dataContainerTop}>
                  <h3 style={{ textTransform: "uppercase" }}>{vendor}</h3>
                  <h1>{title}</h1>
                  <h3>{description}</h3>
                  {/* INFO BOX START >> PRICE & MATERIAL DATA */}
                  <div className={modalStyle.infoBox}>
                    <div className={modalStyle.infoContent}>
                      <div className={modalStyle.infoContentHeader}>
                        <h4>Price</h4>
                      </div>
                      <div className={modalStyle.infoContentBody}>
                        <div className={modalStyle.price}>
                          <div className={modalStyle.value}>
                            ${parseFloat(price).toFixed(2)}
                          </div>
                          <div className={modalStyle.uom}>/ sq.ft.</div>
                          <span className={modalStyle.disclaimer}>
                            Price for reference only.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={modalStyle.infoContent}>
                      <div className={modalStyle.infoContentHeader}>
                        <h4>Material Data</h4>
                      </div>
                      <div className={modalStyle.infoContentBody}>
                        <h4>Status: {status}</h4>
                        <h4>Type: {substrate}</h4>
                        <h4>Subtype: {type}</h4>
                        <h4>Group: {subtype}</h4>
                      </div>
                    </div>
                  </div>
                  {/* INFO BOX START >> Character & Finish */}
                  <div className={modalStyle.infoBox}>
                    <div className={modalStyle.infoContent}>
                      <div className={modalStyle.infoContentHeader}>
                        <h4>CHARACTER</h4>
                      </div>
                      <div className={modalStyle.infoContentBody}>
                        <h4>{character[0]} temper</h4>
                        <h4>{character[1]}</h4>
                      </div>
                    </div>
                    <div className={modalStyle.infoContent}>
                      <div className={modalStyle.infoContentHeader}>
                        <h4>Finish</h4>
                      </div>
                      <div className={modalStyle.infoContentBody}>
                        {feature?.map((fin) => (
                          <h4 key={fin}>{fin}</h4>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* END CHARACTER & FINISH */}
                  {/* INFO BOX START >> softness */}
                  {/* <div className={modalStyle.infoBox}>
                    <div
                      style={{ width: "100%" }}
                      className={modalStyle.infoContent}
                    >
                      <div className={modalStyle.infoContentHeader}>
                        <h4>softness {softness}</h4>
                      </div>
                      <div className={modalStyle.infoContentBody}>
                        <span
                          style={{
                            width: `calc((${softness}/5) * 100% )`,
                            height: "5px",
                            backgroundColor: "#00ffed",
                            display: "flex",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div> */}
                  {/* END SOFTNESS */}
                  {/* INFO BOX START >> SUSTAINABILITY */}
                  <div className={modalStyle.infoBox}>
                    <div
                      style={{ width: "100%" }}
                      className={modalStyle.infoContent}
                    >
                      <div className={modalStyle.infoContentHeader}>
                        <h4>SUSTAINABILIY</h4>
                      </div>
                      <div className={modalStyle.infoContentBody}>
                        <h4>Asiatan Kind Leather</h4>
                        <h4>Carbon Neutral Athletic Leather</h4>
                        <h4>Common Crust Program</h4>
                        <h4>90% Cutting Value for Reduced waste</h4>
                      </div>
                    </div>
                  </div>
                  {/* END SUSTAINABILITY */}
                  {/* Start Edit data */}
                  {userInformation.userType === "vendor" && (
                    <div className={modalStyle.infoBox}>
                      <div className={modalStyle.infoContent}>
                        <div
                          className={`${modalStyle.infoContentBody} ${modalStyle.editButton}`}
                          onClick={() => handleEdit(vendor, slug)}
                        >
                          {editIcon}
                          <h4 className={modalStyle.pageLink}>Edit {title}</h4>
                          <h4
                            style={{
                              borderLeft: "3px solid #DDD",
                              paddingLeft: "20px",
                              height: "10px",
                            }}
                          >
                            view Count: {view_count}
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* End Edit data */}
                </div>
                {/* Start data container bottom */}
                <div className={modalStyle.dataContainerBottom}>
                  <div className={modalStyle.bottomButton}>
                    <h4>Add to collection</h4>
                  </div>
                  {!imageDownload ? (
                    <div
                      className={modalStyle.bottomButton}
                      onClick={() => handleDownloadImage(vendor, slug)}
                    >
                      <h4>Download Image</h4>
                    </div>
                  ) : (
                    <div className={modalStyle.bottomButton}>
                      <h4>Downloading</h4>
                    </div>
                  )}
                </div>
                {/* End data container bottom */}
              </div>
            </div>
          </div>
        </div>
        <div className={singleStyle.singleSimilar}>
          <FilteredLibrary
            id={id}
            terms={character}
            vendor={vendor}
            title={title}
            allMaterials={allMaterials}
            control={subtype.toLowerCase()}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {!isFailed && materialData ? (
        <div className="content">
          <div className="loaderUp">
            <h3>{vendor.replaceAll("-", " ").toUpperCase()}</h3>
            <h2>{slug.replaceAll("-", " ").toUpperCase()}</h2>
            <div className="spinner" />
          </div>
        </div>
      ) : (
        <div className="content">
          <div className="empty-collection">
            <div className="empty-collection-message">
              <div className="text-box">
                <h2 className="login-view-heading">
                  Can't find {slug} from {vendor}
                </h2>
                <button className="button" onClick={handleBackToLibrary}>
                  return to library
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SingleMaterial;
