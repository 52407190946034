import { useEffect, useState, useRef } from "react";
import FindSimilarFunction from "../utilities/FindSimilarFunction";
import Swatch from "./Swatch";
import { downArrowIcon } from "./Icons";
import singleStyle from "../styles/single.module.scss";

function FilteredLibrary({ id, terms, vendor, title, allMaterials, control }) {
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const myRef = useRef(null);
  const orderBy = "none";

  useEffect(() => {
    setFilteredMaterials(
      FindSimilarFunction(id, terms, title, allMaterials, orderBy, control)
    );
    // eslint-disable-next-line
  }, [allMaterials, terms, control]);

  const executeScroll = () => {
    const barHeight = "90px";
    myRef.current.style.scrollMargin = barHeight;
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        id="similarWindow"
        ref={myRef}
        className={singleStyle.singleSimilarHeader}
      >
        <div className={singleStyle.scrollarrow} onClick={executeScroll}>
          {downArrowIcon}
        </div>
        <h5>
          {" "}
          {filteredMaterials?.length === 1
            ? `only ${filteredMaterials.length} similar material to ${vendor} ${title}`
            : `${filteredMaterials.length} similar materials to ${vendor} ${title}`}
        </h5>
      </div>
      <div className={singleStyle.singleSimilarLibrary}>
        {filteredMaterials?.map((material) => (
          <Swatch key={material.id} id={material.id} />
        ))}
      </div>
    </>
  );
}

export default FilteredLibrary;
