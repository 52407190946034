import { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { MaterialContext } from "../context/MaterialContext";
import Swatch from "../components/Swatch";
import { starOutlineIcon } from "../components/Icons";
import CollectionMailModal from "../components/CollectionMailModal";
//import CollectionControl from "../components/CollectionControl";
//import CollectionPdf from "../components/CollectionPdf";

function Collection() {
  const { allMaterials } = useContext(MaterialContext);
  const { collection, updateCollection } = useContext(UserContext);
  const [checkedCollection, setCheckedCollection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showViewed, setShowViewed] = useState(false);
  const [viewedMaterials, setViewedMaterials] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const viewThingStyle = {
    minWidth: "300px",
    minHeight: "270px",
    backgroundImage:
      "linear-gradient(135deg, #272b2b 25%, #283030 25%, #283030 50%, #272b2b 50%, #272b2b 75%, #283030 75%, #283030 100%)",
    backgroundSize: "84.85px 84.85px",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    border: "thin solid #00000060",
    color: " #FEFEFE",
    flexGrow: 1,
    flexShrink: 0.88,
  };

  const haveCollection = collection.length >= 1 ? true : false;

  useEffect(() => {
    const isExist = allMaterials
      .filter(({ id }) => collection.includes(id))
      .map((item) => item);
    if (isExist.length === collection.length) {
      setCheckedCollection(isExist);
      //setStoredCollection(true);
      setLoaded(true);
    } else {
      if (collection.length >= 1 && collection.length !== isExist.length) {
        const notExist = collection
          .filter((item) => isExist.includes(item))
          .map((item) => item);
        setCheckedCollection(notExist);
        setLoaded(true);
        updateCollection(notExist);
      }
    }
    if (!haveCollection) {
      const storedViewedMats = localStorage.getItem("viewedMats");
      const viewedMats = JSON.parse(storedViewedMats);
      if (storedViewedMats && viewedMats.length) {
        const viewedMaterials = allMaterials
          .filter(({ id }) => viewedMats.includes(id))
          .map((item) => item);
        setCheckedCollection(viewedMaterials);
        setViewedMaterials(true);
        setLoaded(true);

        // updateCollection(viewedMaterials);
      }
    }
  }, [collection, allMaterials, updateCollection, haveCollection]);

  if (loaded & (collection.length >= 1)) {
    return (
      <div className="content">
        <CollectionMailModal
          setShowModal={setShowModal}
          showModal={showModal}
          checkedCollection={checkedCollection}
          collection={collection}
        />
        <div className="swatch-view">
          {checkedCollection.map((item) => (
            <Swatch key={item.id} id={item.id} />
          ))}
          <div style={viewThingStyle}>
            <button
              className="button-highlight"
              onClick={() => setShowModal(true)}
            >
              Export collection to PDF
            </button>
            <button
              className="button-highlight"
              onClick={() => updateCollection([])}
            >
              Clear collection
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="content">
        {showViewed ? (
          <div className="swatch-view">
            {checkedCollection.map((item) => (
              <Swatch key={item.id} id={item.id} />
            ))}
            <div className="viewed-materials-control" style={viewThingStyle}>
              <h4>Control Viewed Materials</h4>
              <button className="button-highlight">
                Clear Viewed Materials
              </button>
              {viewedMaterials ? (
                <button
                  className="button-highlight"
                  onClick={() => setShowViewed(!showViewed)}
                >
                  {showViewed
                    ? `Hide ${checkedCollection.length} viewed materials`
                    : `Show ${checkedCollection.length} viewed materials`}
                </button>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="empty-collection">
          <div className="empty-collection-message">
            <div className="text-box">
              <h2>You don't have any materials in your collection.</h2>
              <h4>
                Click on the {starOutlineIcon} to save selected swatches to your
                collection
              </h4>
              {viewedMaterials ? (
                <button
                  className="button-highlight"
                  onClick={() => setShowViewed(!showViewed)}
                >
                  {showViewed
                    ? `Hide ${checkedCollection.length} viewed materials`
                    : `Show ${checkedCollection.length} viewed materials`}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // if (!storedCollection) {
  //   return (
  //     <div className="content">
  //       <h2>Your Collection</h2>
  //       <div className="swatch-view">
  //         {storedCollectionArray?.length >= 1 ? (
  //           <>
  //             {storedCollectionArray.map((id) => (
  //               <Swatch key={id} id={id} />
  //             ))}
  //             {/* <CollectionControl
  //               setStoredTitle={setStoredTitle}
  //               storedTitle={storedTitle}
  //               userId={userId}
  //               setCheckedCollection={setCheckedCollection}
  //               collection={collection}
  //               haveCollection={haveCollection}
  //               storedCollection={storedCollection}
  //               setStoredCollection={setStoredCollection}
  //               setStoredCollectionArray={setStoredCollectionArray}
  //             /> */}
  //           </>
  //         ) : (
  //           <div className="empty-collection">
  //             <div className="empty-collection-message">
  //               <div className="text-box">
  //                 <h2>You don't have and selected any materials yet.</h2>
  //                 <h4>
  //                   Click on the {starOutlineIcon} to save selected swatches to
  //                   your collection
  //                 </h4>
  //               </div>
  //               {/* <CollectionControl
  //                 setStoredTitle={setStoredTitle}
  //                 storedTitle={storedTitle}
  //                 userId={userId}
  //                 setCheckedCollection={setCheckedCollection}
  //                 collection={collection}
  //                 haveCollection={haveCollection}
  //                 storedCollection={storedCollection}
  //                 setStoredCollection={setStoredCollection}
  //               /> */}
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   );
  // } else {
  //   return (
  //     <div className="content">
  //       <div className="empty-collection">
  //         <div className="empty-collection-message">
  //           <div className="text-box">
  //             <h2>Loading collections...</h2>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
}

export default Collection;
