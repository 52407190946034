import { useState, useContext, useEffect, useRef } from "react";
//import { useNavigate } from "react-router-dom";
//import { UserContext } from "../contexts/UserContext";
import { MaterialContext } from "../context/MaterialContext";
//import MaterialViewCount from "../utilities/MaterialViewCount";
import modalStyle from "../styles/modal.module.scss";
//import { editIcon, closeIcon } from "./Icons";
//import ImageDownload from "../utilities/ImageDownload";
//import ReactGA from "react-ga4";
//import MobileMaterialModal from "./mobile/mobileMaterialModal";
function MaterialView({ id }) {
  const media = "laptop";
  console.log(id);
  const { materials, ROOT_SERVER, allMaterials } = useContext(MaterialContext);
  const [materialData, setMaterialData] = useState([]);
  const [viewing, setViewing] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageNumber, setImageNumber] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [modalDimensions, setModalDimensions] = useState([]);
  //const [imageDownload, setImageDownload] = useState(false);

  const getModalDimension = useRef(null);

  const handleImageLoaded = () => {
    setImageLoaded(true);
    setModalDimensions([
      getModalDimension?.current?.clientHeight,
      getModalDimension?.current?.clientWidth,
    ]);
  };

  const handleChangeImage = (i) => {
    if (i !== imageNumber) {
      setImageLoaded(false);
      setImageNumber(i);
    }
  };
  console.log(error);
  useEffect(() => {
    setLoaded(false);
    if (id) {
      setError(false);
      const getMaterial = allMaterials.find((material) => material.id === id);
      if (getMaterial) {
        setMaterialData(getMaterial);
        setViewing(
          `${ROOT_SERVER}${getMaterial.vendor}/${getMaterial.slug}/${
            media === "mobile" ? "swatch" : "full"
          }/${getMaterial.images[imageNumber]}`
        );
        // ReactGA.event({
        //   category: "liked-material",
        //   action: `${getMaterial.vendor} - ${getMaterial.slug}`,
        // });
        //body.style.overflow = "hidden";
        setLoaded(true);
      } else {
        const getMaterial = allMaterials.find((material) => material.id === id);
        console.log("error in Material Modal", getMaterial);
        setError(true);
      }
    }
    // eslint-disable-next-line
  }, [materials, imageNumber, id]);

  const {
    title,
    slug,
    description,
    price,
    status,
    type,
    subtype,
    images,
    substrate,
    vendor,
    character,
    feature,
  } = materialData;

  return (
    <div className={modalStyle.materialModal}>
      {loaded ? (
        <div className={modalStyle.modalContent}>
          {/* IMAGE CONTAINER START */}
          <div
            className={modalStyle.imageContainer}
            style={{ height: modalDimensions[0] }}
          >
            {!imageLoaded && <div className="spinner" />}
            <img
              className={modalStyle.materialImage}
              src={viewing}
              alt={`${vendor} - ${title}`}
              onLoad={handleImageLoaded}
              style={
                imageLoaded ? { filter: "blur(0px)" } : { filter: "blur(6px)" }
              }
            />
            <div className={modalStyle.imageSelector}>
              {images.length >= 2 &&
                images?.map((image, i) => (
                  <div
                    key={image + " -- " + i}
                    data-image={image + " -- " + i}
                    className={`${modalStyle.swatch} ${
                      i === imageNumber && modalStyle.active
                    }`}
                    style={{
                      overflow: "hidden",
                    }}
                    onClick={() => handleChangeImage(i)}
                  >
                    <img
                      src={`${ROOT_SERVER}${vendor}/${slug}/thumbnail/${image}`}
                      alt={slug + i}
                    />
                  </div>
                ))}
            </div>
          </div>
          {/* IMAGE CONTAINER END */}
          {/* DATA CONTAINER START */}
          <div className={modalStyle.dataContainer} style={{ height: "800px" }}>
            <div className={modalStyle.dataContainerTop}>
              <h3 style={{ textTransform: "uppercase" }}>{vendor}</h3>
              <h1>{title}</h1>
              <h3>{description}</h3>
              {/* INFO BOX START >> PRICE & MATERIAL DATA */}
              <div className={modalStyle.infoBox}>
                <div className={modalStyle.infoContent}>
                  <div className={modalStyle.infoContentHeader}>
                    <h4>Price</h4>
                  </div>
                  <div className={modalStyle.infoContentBody}>
                    <div className={modalStyle.price}>
                      <div className={modalStyle.value}>
                        ${parseFloat(price).toFixed(2)}
                      </div>
                      <div className={modalStyle.uom}>/ sq.ft.</div>
                      <span className={modalStyle.disclaimer}>
                        Price for reference only.
                      </span>
                    </div>
                  </div>
                </div>
                <div className={modalStyle.infoContent}>
                  <div className={modalStyle.infoContentHeader}>
                    <h4>Material Data</h4>
                  </div>
                  <div className={modalStyle.infoContentBody}>
                    <h6>Type: {substrate}</h6>
                    <h6>Subtype: {type}</h6>
                    <h6>Group: {subtype}</h6>
                    <h6>Status: {status}</h6>
                  </div>
                </div>
              </div>
              {/* INFO BOX START >> Character & Finish */}
              <div className={modalStyle.infoBox}>
                <div className={modalStyle.infoContent}>
                  <div className={modalStyle.infoContentHeader}>
                    <h4>CHARACTER</h4>
                  </div>
                  <div className={modalStyle.infoContentBody}>
                    <h4>{character[0]} temper</h4>
                    <h4>{character[1]}</h4>
                  </div>
                </div>
                <div className={modalStyle.infoContent}>
                  <div className={modalStyle.infoContentHeader}>
                    <h4>Finish</h4>
                  </div>
                  <div className={modalStyle.infoContentBody}>
                    {feature?.map((fin, i) => (
                      <h4 key={fin + i}>{fin}</h4>
                    ))}
                  </div>
                </div>
              </div>
              {/* END CHARACTER & FINISH */}
              {/* INFO BOX START >> SUSTAINABILITY */}
              {/* {infoBox(type, vendor)} */}
              {/* END SUSTAINABILITY */}
              {/* INFO BOX START >> Brand Data */}
              {/* {brandData.length > 0 && (
                    <div className={modalStyle.infoBox}>
                      <div
                        style={{ width: "100%" }}
                        className={modalStyle.infoContent}
                      >
                        <div className={modalStyle.infoContentHeader}>
                          <h4>Nike Data</h4>
                        </div>
                        <div className={modalStyle.infoContentBody}>
                          {brandData.map((data, i) => (
                            <h4 key={data.pcx_material_number + i}>
                              {title} - {data.thickness}mm - PCX#:
                              {data.pcx_material_number}
                            </h4>
                          ))}
                        </div>
                      </div>
                    </div>
                  )} */}
              {/* END Brand data */}
              {/* Start Edit data */}
              {/* {userInformation.userType === "vendor" && (
                    <div className={modalStyle.infoBox}>
                      <div className={modalStyle.infoContent}>
                        <div
                          className={`${modalStyle.infoContentBody} ${modalStyle.editButton}`}
                        >
                          {editIcon}
                          <h4 className={modalStyle.pageLink}>Edit {title}</h4>
                        </div>
                      </div>
                    </div>
                  )} */}
              {/* End Edit data */}
            </div>
            <div className={modalStyle.dataContainerBottom}>
              <div className={modalStyle.bottomButton}>
                <h4 className={modalStyle.pageLink}>More like this article</h4>
              </div>
              <div className={modalStyle.bottomButton}>
                <h4>Download Image</h4>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>loading</div>
      )}
    </div>
  );
}

export default MaterialView;
